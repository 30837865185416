import React from "react"

import "./PrivacyPolicyContent.scss"
import Container from "@material-ui/core/Container"

const PrivacyPolicyContent = () => {
  return (
    <div className="privacy-policy">
      <Container>
        <div className="left-title">Privacy Policy</div>
        <div className="content-description">
          Your privacy is very important to us. Accordingly, we have developed
          this Policy in order for you to understand how we collect, use,
          communicate and disclose and make use of personal information. The
          following outlines our privacy policy.
        </div>
        <div className="content-description">
          Before or at the time of collecting personal information, we will
          identify the purposes for which information is being collected.
        </div>
        <div className="content-description">
          We will collect and use of personal information solely with the
          objective of fulfilling those purposes specified by us and for other
          compatible purposes, unless we obtain the consent of the individual
          concerned or as required by law.
        </div>
        <div className="content-description">
          We will only retain personal information as long as necessary for the
          fulfillment of those purposes.
        </div>
        <div className="content-description">
          Before or at the time of collecting personal information, we will
          identify the purposes for which information is being collected.
        </div>
        <div className="content-description">
          We will collect personal information by lawful and fair means and,
          where appropriate, with the knowledge or consent of the individual
          concerned.
        </div>
        <div className="content-description">
          Personal data should be relevant to the purposes for which it is to be
          used, and, to the extent necessary for those purposes, should be
          accurate, complete, and up-to-date.
        </div>
        <div className="content-description">
          We will make readily available to customers information about our
          policies and practices relating to the management of personal
          information.
        </div>

        <div className="content-description">
          We are committed to conducting our business in accordance
          with these principles in order to ensure that the confidentiality of
          personal information is protected and maintained.
        </div>
      </Container>
    </div>
  )
}

export default PrivacyPolicyContent
