import React from 'react';
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PrivacyPolicyContent from "../components/PrivacyPolicyContent"

const PrivacyPolicy = () => {
  return (
    <Layout currentPage="privacy-policy">
      <SEO title="Privacy Policy" />
      <PrivacyPolicyContent />
    </Layout>
  );
};

export default PrivacyPolicy;
